import { useMutation, useQueryClient } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import TicketDetailsForm, {
    TicketDetailsFormValues,
} from 'src/app/components/forms/TicketDetailsForm';
import {
    GET_PURCHASE_BY_ID_QUERY_KEY,
    useFetchPurchaseById,
} from 'src/app/hooks/purchases/useFetchPurchaseById';
import { useUpdatePurchasePrice } from 'src/app/hooks/purchases/useUpdatePurchasePrice';
import {
    GET_TICKET_BY_ID_QUERY_KEY,
    useGetTicketById,
} from 'src/app/hooks/tickets/useGetTicketById';
import { useUpdateTicketBlockRowSeat } from 'src/app/hooks/tickets/useUpdateTicketBlockRowSeat';
import mapTicketDataToViewModel from 'src/app/utilities/mappers/mapTicketDataToFormValues';
import ChangeSeatDto from 'src/data/dtos/ChangeSeatDto';
import { PURCHASE_STATUS } from 'src/data/enums/purchase';
import Price from 'src/data/models/common/price';
import Ticket from 'src/data/models/tickets/ticket';
import ticketsService from 'src/data/services/ticketsService';
import { AutoCompleteOption } from 'src/view/components/auto-complete/interfaces';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';

export interface TicketDetailsFormFeatureProps {
    eventId: string;
    ticketId: string;
    onFetchFinished: (purchaseId: string, orderId: string | null) => void;
    onTicketUpdated?: () => void;
    onTicketFetched: (ticket: Ticket) => void;
}

export default function TicketDetailsFormFeature({
    eventId,
    ticketId,
    onFetchFinished,
    onTicketUpdated,
    onTicketFetched,
}: TicketDetailsFormFeatureProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [purchaseId, setPurchaseId] = useState('');
    const [defaultValues, setDefaultValues] = useState<TicketDetailsFormValues | undefined>();
    const queryClient = useQueryClient();

    const { isLoading: purchaseIsLoading, data: purchaseData } = useFetchPurchaseById(purchaseId, {
        enabled: !!purchaseId,
    });

    const { isLoading: loading, data: ticketData } = useGetTicketById(ticketId, {
        enabled: !!ticketId,
        onSuccess: (ticket) => {
            setPurchaseId(ticket?.data.data.purchaseId);
        },
    });

    const [ticketIsUpdating, setTicketIsUpdating] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [ticket, setTicket] = useState<Ticket | undefined>();
    const [assignedRowId, setAssignedRowId] = useState<string | undefined | null>();
    const [assignedSeat, setAssignedSeat] = useState<AutoCompleteOption | undefined>();
    const [isTicketLocked, setIsTicketLocked] = useState(false);

    useEffect(() => {
        if (!ticketData) return;

        const ticket: Ticket = ticketData.data.data;
        onTicketFetched(ticket);

        const { purchaseId, orderId, rowId, seatId, seatNumber, isLocked } = ticket;

        setIsTicketLocked(isLocked);

        setTicket(ticket);

        setDefaultValues(mapTicketDataToViewModel(ticket));

        onFetchFinished(purchaseId, orderId);

        setAssignedRowId(rowId);

        if (seatId && seatNumber) {
            setAssignedSeat({
                label: seatNumber,
                value: seatId,
            });
        }
    }, [ticketData, onFetchFinished, onTicketFetched]);

    const getSumOfNewTicketPrices = useCallback(
        (updatedPricePerTicket: number) => {
            const total = new BigNumber(purchaseData?.data.data.numTickets || 0);

            return total.times(updatedPricePerTicket).toNumber();
        },
        [purchaseData]
    );

    const { mutateAsync: updateTicketBlockRowSeat } = useUpdateTicketBlockRowSeat(() => {
        queryClient.invalidateQueries({
            queryKey: [GET_TICKET_BY_ID_QUERY_KEY],
        });
    });

    const { mutateAsync: updatePurchasePrice } = useUpdatePurchasePrice(() => {
        queryClient.invalidateQueries({
            queryKey: [GET_PURCHASE_BY_ID_QUERY_KEY],
        });
    });

    const onFormSubmit = async (values: TicketDetailsFormValues): Promise<void> => {
        if (!ticket) return;

        const { block, row, seat, purchasePrice } = values;

        const blockId: string | undefined = block?.value;
        const rowId: string | undefined = row?.value;
        const seatId: string | null = seat?.value || null;

        try {
            setTicketIsUpdating(true);

            if (blockId && rowId) {
                const blockRowSeatDto: ChangeSeatDto = {
                    blockId,
                    rowId,
                    seatId,
                };

                await updateTicketBlockRowSeat({ id: ticket.id, dto: blockRowSeatDto });
            }

            const ticketPrice: Price | undefined =
                purchasePrice?.value && purchasePrice?.currency ? purchasePrice : undefined;
            const isTicketPriceChanged = !isEqual(purchasePrice, ticket.purchasePrice);

            const isPriceUpdated = isTicketPriceChanged && ticketPrice;

            if (isPriceUpdated && purchaseData && ticketData) {
                const { eventId, seatingPlanCategoryId } = ticketData.data.data;

                const updateCategoryTicketPriceDto = {
                    purchaseOriginalPrice: getSumOfNewTicketPrices(ticketPrice.value || 0),
                    seatingPlanCategoryPrices: [
                        {
                            eventId,
                            seatingPlanCategoryId,
                            pricePerTicket: ticketPrice?.value || 0,
                        },
                    ],
                };

                await updatePurchasePrice({
                    id: purchaseData.data.data.id,
                    dto: updateCategoryTicketPriceDto,
                });
            }

            enqueueSnackbar(`Ticket has been updated`, {
                variant: 'success',
            });

            setEditMode(false);

            if (onTicketUpdated) onTicketUpdated();

            setTicketIsUpdating(false);
        } catch (e) {
            setTicketIsUpdating(false);

            enqueueSnackbar(`We couldn't update the ticket, please try again`, {
                variant: 'error',
            });
        }
    };

    // FIXME: Move to a dedicated hook file
    const { mutate: lockTicketMutation, isLoading: isLockingTicket } = useMutation({
        mutationFn: async (ticketId: string) => ticketsService.lockTicket(ticketId),
        onSuccess: () => {
            setIsTicketLocked(true);
            enqueueSnackbar('Successfully locked the ticket!', {
                variant: 'success',
            });
            if (onTicketUpdated) onTicketUpdated();

            queryClient.invalidateQueries({
                queryKey: [GET_TICKET_BY_ID_QUERY_KEY],
            });
        },
        onError: () => {
            enqueueSnackbar(`Something went wrong while locking the ticket`, {
                variant: 'error',
            });
        },
    });

    // FIXME: Move to a dedicated hook file
    const { mutate: unlockTicketMutation, isLoading: isUnlockingTicket } = useMutation({
        mutationFn: async (ticketId: string) => ticketsService.unlockTicket(ticketId),
        onSuccess: () => {
            setIsTicketLocked(false);
            enqueueSnackbar('Successfully unlocked the ticket!', {
                variant: 'success',
            });
            if (onTicketUpdated) onTicketUpdated();

            queryClient.invalidateQueries({
                queryKey: [GET_TICKET_BY_ID_QUERY_KEY],
            });
        },
        onError: () => {
            enqueueSnackbar(`Something went wrong while unlocking the ticket`, {
                variant: 'error',
            });
        },
    });

    function toggleTicketLockedStatus() {
        if (!ticket) return;

        isTicketLocked ? unlockTicketMutation(ticket.id) : lockTicketMutation(ticket.id);
    }

    if (loading || purchaseIsLoading) return <LoadingOverlay />;

    return (
        <TicketDetailsForm
            loading={ticketIsUpdating}
            standardTicket={!!ticket && !!ticket.purchasePrice}
            defaultValues={defaultValues}
            onFormSubmit={onFormSubmit}
            eventId={eventId}
            editMode={editMode}
            assignedRowId={assignedRowId}
            assignedSeat={assignedSeat}
            toggleEditMode={() => setEditMode(!editMode)}
            isContractTicket={ticket?.isContractTicket}
            purchaseStatus={purchaseData?.data.data.status as PURCHASE_STATUS}
            onLockStatusChange={toggleTicketLockedStatus}
            isTicketLocked={!!ticketData?.data.data.isLocked}
            isUpdatingLockedStatus={isLockingTicket || isUnlockingTicket}
        />
    );
}
