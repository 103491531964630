import Table from 'src/view/components/table/table/Table';
import { NewTableColumn, TABLE_CELL_WIDTH } from 'src/view/components/table/table/Types';
import { EventTicketsSalesCap } from 'src/data/models/events/event';
import { IconButton, List, ListItem, Theme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { WarningText } from 'src/view/components/warning-text/WarningText';
import HtmlListTooltip from 'src/view/components/tooltip/HtmlListTooltip';

const useStyles = makeStyles((theme: Theme) => ({
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    spacingRight: {
        marginRight: theme.spacing(2),
    },
    listItem: {
        width: 200,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    tscIndex: {
        marginRight: theme.spacing(2),
        fontWeight: 'bold',
    },
}));

export interface TicketSalesCapsTableProps {
    eventTicketSalesCaps?: EventTicketsSalesCap[];
    loading?: boolean;
    onEditClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
}

export default function TicketSalesCapsTable({
    loading,
    eventTicketSalesCaps = [],
    onEditClick,
    onDeleteClick,
}: TicketSalesCapsTableProps): JSX.Element {
    const classes = useStyles();
    const columns: NewTableColumn<EventTicketsSalesCap>[] = [
        {
            key: 'seatingPlanCategoryName',
            title: 'Seatingplan category',
            cellRenderer: (tsc) => (
                <span>
                    {tsc.seatingPlanCategoryName}{' '}
                    {tsc.isArchived && <WarningText text="archived" />}
                </span>
            ),
            width: TABLE_CELL_WIDTH.LARGE,
        },
        {
            key: 'salesCap',
            title: 'Sales cap',
            cellRenderer: (tsc) => tsc.salesCap,
            width: TABLE_CELL_WIDTH.MEDIUM,
        },
        {
            key: 'price',
            title: 'Price',
            cellRenderer: (tsc) => `${tsc.price.currency} ${tsc.price.value}`,
            width: TABLE_CELL_WIDTH.MEDIUM,
        },

        {
            key: 'salesProfiles',
            title: 'Sales profiles',
            cellRenderer: (tsc) => {
                if (tsc.salesProfiles.length === 0) return '';

                if (tsc.salesProfiles.length === 1) return tsc.salesProfiles[0].name;

                const tooltipData = tsc.salesProfiles.map((t, index) => (
                    <ListItem
                        divider
                        className={classNames(classes.listItem)}
                        key={`${t.name}-${index}`}
                    >
                        <span className={classes.tscIndex}>{index + 1}.</span>
                        {t.name}
                    </ListItem>
                ));

                const tooltipTitleData = <List>{tooltipData}</List>;

                return (
                    <HtmlListTooltip
                        buttonTitle={`${tsc.salesProfiles.length} platforms`}
                        title={tooltipTitleData}
                    />
                );
            },
        },
        {
            width: TABLE_CELL_WIDTH.MEDIUM,
            cellRenderer: (rowData) => {
                return (
                    <div className={classes.buttonsContainer}>
                        <IconButton
                            onClick={() => onEditClick(rowData.id)}
                            className={classes.spacingRight}
                            color="primary"
                            size={'small'}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => onDeleteClick(rowData.id)}
                            className={classes.spacingRight}
                            color="error"
                            size={'small'}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    return (
        <Table<EventTicketsSalesCap>
            data={eventTicketSalesCaps}
            rowIdResolver={(rowData) => ({
                id: rowData.id,
            })}
            columns={columns}
            loading={loading}
            minWidth={650}
        />
    );
}
