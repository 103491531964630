import { Dropzone, DropzoneComponentProps } from 'src/view/components/dropzone-input/Dropzone';
import { DropEvent, DropzoneProps, FileRejection } from 'react-dropzone';
import file from 'src/app/constants/constants/file-types/file';
import { ChildlessBaseComponent } from 'src/view/interfaces/BaseComponent';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import unprocessedTicketFileService from 'src/data/services/unprocessedTicketFileService';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import Toaster from 'src/app/utilities/helpers/Toaster';
import ErrorDetail from 'src/data/api/responses/ErrorDetail';

export interface FileData {
    fileId: string | null;
    name: string;
    error?: ErrorDetail;
}

interface FileUploadFeatureProps extends ChildlessBaseComponent {
    onFileUploaded: (file: FileData) => void;
    onLoadingStateChanged?: (state: boolean) => void;
    onFileUploadFailed?: (file: Omit<FileData, 'fileId'>) => void;
    dropzoneComponentProps?: DropzoneComponentProps;
    dropzoneProps?: DropzoneProps;
    eventId: string;
}

export const UnprocessedTicketFileUploadFeature = ({
    onFileUploaded,
    onLoadingStateChanged,
    onFileUploadFailed,
    className,
    dropzoneComponentProps,
    dropzoneProps,
    eventId,
}: FileUploadFeatureProps) => {
    const maximumAllowedFilesAtOnce = 100;
    const queryClient = useQueryClient();

    const { mutateAsync: uploadFile, isLoading } = useMutation({
        mutationFn: (file: File) =>
            unprocessedTicketFileService.uploadUnprocessedTicketFile(eventId, file),
    });

    const handleFileUpload = async (file: File) => {
        try {
            const resp = await uploadFile(file);
            const uploadedFile = { fileId: resp.data.data.id, name: file.name };

            onFileUploaded(uploadedFile);
        } catch (error) {
            onFileUploadFailed?.({ name: file.name });
            const parsedError = parseErrors(error)[0];

            const failedUploadFile = {
                fileId: null,
                name: file.name,
                error: parsedError,
            };

            onFileUploaded(failedUploadFile);
        }
    };

    const handleDrop = async (
        acceptedFiles: File[],
        fileRejections: FileRejection[],
        event: DropEvent
    ) => {
        if (fileRejections.length) {
            const isMaxFilesAtOnceLimitExceeded = fileRejections.every(
                (r) => r.errors[0]?.code === 'too-many-files'
            );

            if (isMaxFilesAtOnceLimitExceeded) {
                Toaster.toast(
                    `Selection includes more than the allowed number of files at once, which is ${maximumAllowedFilesAtOnce}.`,
                    {
                        variant: 'error',
                    }
                );

                return;
            }
        }
        if (dropzoneProps?.onDrop) {
            dropzoneProps.onDrop(acceptedFiles, fileRejections, event);

            return;
        }

        onLoadingStateChanged?.(true);

        await Promise.all(
            acceptedFiles.map(async (file) => {
                await handleFileUpload(file);
            })
        );

        onLoadingStateChanged?.(false);
        queryClient.invalidateQueries({ queryKey: ['unprocessedTicketFiles'] });
    };

    return (
        <Dropzone
            className={className}
            loading={dropzoneComponentProps?.loading || isLoading}
            onDrop={handleDrop}
            accept={dropzoneProps?.accept || file.ticketFilesAcceptedMimeTypes}
            maxFiles={maximumAllowedFilesAtOnce}
            {...dropzoneProps}
            {...dropzoneComponentProps}
        />
    );
};
