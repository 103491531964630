import { Theme } from '@material-ui/core';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { TicketUploadFilesFormFeature } from 'src/app/components/features/tickets/TicketUploadFilesFormFeature';
import formatTicketType from 'src/app/utilities/helpers/formatTicketType';
import { PURCHASE_STATUS } from 'src/data/enums/purchase';
import Price from 'src/data/models/common/price';
import Ticket from 'src/data/models/tickets/ticket';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import { BlockRowSeatRange } from 'src/view/components/block-row-seat/BlockRowSeatRange';
import Chip from 'src/view/components/chip/Chip';
import Popover from 'src/view/components/popover/Popover';
import { StatusLabel as StatusLabelComponent } from 'src/view/components/status-label/StatusLabel';
import StatusLabel from 'src/view/components/status-symbol/StatusSymbol';

import Table, { RowIdResolver, TableProps } from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TABLE_CELL_HEIGHT,
    TABLE_CELL_WIDTH,
    TABLE_ROW_TYPE,
    TableColumnSorting,
    TablePaginationData,
} from 'src/view/components/table/table/Types';
import { WarningText } from 'src/view/components/warning-text/WarningText';
import { getTicketsConnectionLines } from '../../utilities/helpers/selectedTicketRows';
import { getStatusChipColor } from './PurchasesTable';

const useChipStyles = makeStyles((theme: Theme) => ({
    root: {
        minWidth: 50,
        '& .MuiChip-label': {
            padding: 0,
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '60px',
    },
    connectionLine: {
        width: 3,
        height: 10,
        background: theme.palette.grey[200],
        display: 'flex',
        margin: 0,
        padding: 0,
        '&--broken': {
            height: 3,
            background: theme.palette.secondary.light,
        },
        '&--none': {
            height: 3,
            background: 'transparent',
        },
    },
    connectionLineContainer: {
        padding: '0px 5px',
        '&--top': {
            borderTop: `2px solid ${theme.palette.secondary.light}`,
        },
        '&--bottom': {
            borderBottom: `2px solid ${theme.palette.secondary.light}`,
        },
    },
}));

export enum SPLIT_CONNECTION_LINE_TYPES {
    NONE = 'none',
    BROKEN = 'broken',
    CONNECTED = 'full',
}

export interface TicketsTableDataResolver {
    available: boolean;
    blockRowSeat: string;
    ticketType: string;
    blockId?: string | null;
    blockNumber?: string | null;
    rowId?: string | null;
    rowNumber?: string | null;
    seatId?: string | null;
    seatNumber?: string | null;
    purchasePrice: Price | null;
    seatingplanCategoryName?: string;
    seatingplanCategoryId?: string;
    sortOrder?: number;
    splitName?: string;
    splitTotalTicketsCount?: number;
    isLocked: boolean;
    eventId?: string;
    eventName: string;
}

export interface TicketsTableProps {
    includePurchaseData?: boolean;
    includePurchaseStatistics?: boolean;
    onClickDetails?: (ticketId: string) => void;
    defaultSorting?: TableColumnSorting[];
    onChangeSorting?: (sortings: TableColumnSorting[]) => void;
    tickets?: Ticket[];
    loading?: boolean;
    onChangeSelectedRows?: (selectedRows: RowIdResolver<TicketsTableDataResolver>[]) => void;
    pagination?: TablePaginationData;
    initialSelectedRows?: RowIdResolver<TicketsTableDataResolver>[];
    enableCheckboxes?: boolean;
    higlightRow?: (ticket: Ticket) => boolean;
    warningRow?: (ticket: Ticket) => boolean;
    includeSortOrderForm?: boolean;
    rowHeight?: TABLE_CELL_HEIGHT;
    onChangeRowOrder?: (data: Ticket[]) => void;
    tableToolbarActions?: TableProps<Ticket, TicketsTableDataResolver>['tableToolbarActions'];
    displayForeignPrice?: boolean;
    includeEventName?: boolean;
}

export default function TicketsTable({
    includePurchaseData,
    includePurchaseStatistics,
    onClickDetails,
    defaultSorting,
    onChangeSorting,
    loading,
    tickets = [],
    onChangeSelectedRows,
    pagination,
    initialSelectedRows,
    enableCheckboxes = true,
    higlightRow,
    warningRow,
    includeSortOrderForm = false,
    rowHeight,
    onChangeRowOrder,
    tableToolbarActions,
    displayForeignPrice,
    includeEventName,
}: TicketsTableProps): JSX.Element {
    const spacingClasses = useSpacingStyles();
    const chipClasses = useChipStyles();
    const splitTicketsConnectionLines = getTicketsConnectionLines(tickets);

    const getSplitConnectionLineClass = (connectionType: SPLIT_CONNECTION_LINE_TYPES) => {
        switch (connectionType) {
            case SPLIT_CONNECTION_LINE_TYPES.NONE:
                return `${chipClasses.connectionLine}--none`;
            case SPLIT_CONNECTION_LINE_TYPES.BROKEN:
                return `${chipClasses.connectionLine}--broken`;
            case SPLIT_CONNECTION_LINE_TYPES.CONNECTED:
                return chipClasses.connectionLine;
            default:
                return `${chipClasses.connectionLine}--none`;
        }
    };

    const renderConnectionLine = (position: string, lineClass = '', connectionLineType: string) => {
        const lineContainerClass = `${chipClasses.connectionLineContainer} ${chipClasses.connectionLineContainer}--${position}`;

        return (
            <div
                className={classNames(
                    connectionLineType === SPLIT_CONNECTION_LINE_TYPES.BROKEN && lineContainerClass
                )}
            >
                <span className={classNames(chipClasses.connectionLine, lineClass)}></span>
            </div>
        );
    };

    const columns: NewTableColumn<Ticket>[] = [
        {
            key: 'blockRowSeatName',
            title: 'Position',
            cellRenderer: (ticket) => ticket.sortOrder,
            width: TABLE_CELL_WIDTH.SMALL,
            hidden: !includeSortOrderForm,
        },
        {
            key: 'blockRowSeatName',
            title: 'Block Row Seat',
            isSortable: true,
            cellRenderer: (ticket) => <BlockRowSeatRange seatings={[ticket]} />,
            width: TABLE_CELL_WIDTH.LARGE,
        },
        {
            key: 'splitName',
            sortingKey: 'split',
            title: 'Split',
            isSortable: true,
            cellRenderer: (ticket) => (
                <span className={spacingClasses.spacingRight}>{ticket.splitName}</span>
            ),
            width: 80,
        },
        {
            key: 'splitTotalTicketsCount',
            title: 'Position',
            cellRenderer: (ticket, _, index = 0) => {
                if (!ticket.splitName) return '';
                if (!ticket.splitTotalTicketsCount) return <></>;
                if (!ticket.sortOrder) ticket.sortOrder = 0;

                const splitConnection = splitTicketsConnectionLines[index];

                const topLineClass = getSplitConnectionLineClass(
                    splitConnection?.topLine as SPLIT_CONNECTION_LINE_TYPES
                );
                const bottomLineClass = getSplitConnectionLineClass(
                    splitConnection?.bottomLine as SPLIT_CONNECTION_LINE_TYPES
                );

                return (
                    <>
                        <div className={chipClasses.container}>
                            {renderConnectionLine('top', topLineClass, splitConnection?.topLine)}
                            <Chip
                                classes={chipClasses}
                                rounded
                                label={`${ticket.sortOrder}/${ticket.splitTotalTicketsCount}`}
                            />
                            {renderConnectionLine(
                                'bottom',
                                bottomLineClass,
                                splitConnection?.bottomLine
                            )}
                        </div>
                    </>
                );
            },
            width: TABLE_CELL_WIDTH.MEDIUM,
            hidden: !includePurchaseStatistics,
        },
        {
            key: 'purchasePrice',
            title: 'Purchase Price',
            cellRenderer: (rowData) => {
                if (displayForeignPrice && rowData.purchasePriceForeign) {
                    const { currency, value } = rowData.purchasePriceForeign;

                    return `${currency.toUpperCase()} ${value}`;
                }

                if (!rowData.purchasePrice) return '';

                const { currency, value } = rowData.purchasePrice;

                return `${currency.toUpperCase()} ${value}`;
            },
        },
        includeEventName
            ? {
                  key: 'eventName',
                  title: 'Event name',
                  width: TABLE_CELL_WIDTH.EXTRA_LARGE,
                  cellRenderer: (rowData) => <span>{rowData.eventName}</span>,
              }
            : {},
        {
            key: 'seatingPlanCategoryName',
            title: 'SeatingPlanCategory',
            width: TABLE_CELL_WIDTH.LARGE,
            cellRenderer: (rowData) => (
                <span>
                    {rowData.seatingPlanCategoryName}{' '}
                    {rowData.seatingplanCategoryIsArchived ? <WarningText text="archived" /> : ''}
                </span>
            ),
        },
        {
            key: 'available',
            title: 'Availability',
            cellRenderer: (rowData) => {
                return rowData.available ? (
                    <StatusLabel label="Available" variant="success" />
                ) : (
                    <StatusLabel label="Not Available" variant="error" />
                );
            },
        },
        {
            key: 'purchaseSummary',
            title: 'Purchase status',
            cellRenderer: (rowData) => {
                return (
                    <StatusLabelComponent
                        text={rowData.purchaseSummary?.purchaseStatus || ''}
                        variant={getStatusChipColor(rowData.purchaseSummary?.purchaseStatus || '')}
                    />
                );
            },
            hidden: !includePurchaseStatistics,
        },

        {
            key: 'type',
            cellRenderer: (rowData) => (
                <Chip
                    variant="outlined"
                    color="primary"
                    tinyChip
                    label={formatTicketType(rowData.type)}
                />
            ),
        },
        {
            title: 'Ticket File',
            width: TABLE_CELL_WIDTH.EXTRA_LARGE,
            cellRenderer: (rowData) => {
                return (
                    <TicketUploadFilesFormFeature
                        eventId={rowData.eventId}
                        ticketId={rowData.id}
                        defaultFiles={rowData.files}
                    />
                );
            },
        },
        {
            title: 'purchase supplier',
            cellRenderer: (rowData) => {
                return rowData.purchaseSummary?.supplier || '';
            },
            width: TABLE_CELL_WIDTH.MEDIUM,
            hidden: !includePurchaseData,
        },
        {
            title: 'purchase notes',
            cellRenderer: (rowData) => {
                const text = rowData.purchaseSummary?.notes || '';

                return (
                    <Popover text={text} isLink={false}>
                        <span>{text}</span>
                    </Popover>
                );
            },
            truncate: true,
        },
        {
            align: 'center',
            cellRenderer: (ticket) =>
                ticket.isContractTicket ? (
                    <Tooltip title="Contract ticket">
                        <HandshakeIcon fontSize="small" color="primary" />
                    </Tooltip>
                ) : (
                    <></>
                ),
            width: TABLE_CELL_WIDTH.TINY,
        },
        {
            align: 'right',
            width: TABLE_CELL_WIDTH.TINY,
            cellRenderer: (rowData) => {
                return rowData.isLocked ? <LockIcon /> : '';
            },
        },
        {
            align: 'right',
            width: TABLE_CELL_WIDTH.TINY,
            cellRenderer: (rowData) => {
                if (onClickDetails) {
                    return (
                        <IconButton
                            color="primary"
                            onClick={() => {
                                onClickDetails(rowData.id);
                            }}
                            size={'small'}
                        >
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    );
                }

                return '';
            },
        },
    ];

    return (
        <Table<Ticket, TicketsTableDataResolver>
            data={tickets}
            rowIdResolver={(rowData: Ticket) => ({
                id: rowData.id,
                data: {
                    available: rowData.available,
                    blockRowSeat: rowData.blockRowSeatName,
                    ticketType: rowData.type,
                    blockId: rowData.blockId,
                    blockNumber: rowData.blockNumber,
                    rowId: rowData.rowId,
                    rowNumber: rowData.rowNumber,
                    seatId: rowData.seatId,
                    seatNumber: rowData.seatNumber,
                    purchasePrice: rowData.purchasePrice,
                    seatingplanCategoryName: rowData.seatingPlanCategoryName,
                    seatingplanCategoryId: rowData.seatingPlanCategoryId,
                    sortOrder: rowData.sortOrder,
                    splitName: rowData.splitName,
                    splitTotalTicketsCount: rowData.splitTotalTicketsCount,
                    isLocked: rowData.isLocked,
                    purchasePriceForeign: rowData.purchasePriceForeign,
                    eventId: rowData.eventId,
                    eventName: rowData.eventName,
                },
            })}
            columns={columns}
            defaultSorting={defaultSorting}
            onSortingChange={onChangeSorting}
            loading={loading}
            minWidth={900}
            enableCheckboxes={enableCheckboxes}
            onChangeSelectedRows={onChangeSelectedRows}
            pagination={pagination}
            initialSelectedRows={initialSelectedRows}
            higlightRow={higlightRow}
            warningRow={warningRow}
            rowHeight={rowHeight}
            onChangeRowOrder={onChangeRowOrder}
            enableDragAndDrop={!!onChangeRowOrder}
            rowProps={(data) => ({
                type:
                    data.purchaseSummary?.purchaseStatus === PURCHASE_STATUS.Cancelled
                        ? TABLE_ROW_TYPE.danger
                        : TABLE_ROW_TYPE.default,
            })}
            tableToolbarActions={tableToolbarActions}
        />
    );
}
