import { zodResolver } from '@hookform/resolvers/zod';
import { CircularProgress, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import BigNumber from 'bignumber.js';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import sumBy from 'lodash/sumBy';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import SupplierSelectDataWrapper from 'src/app/components/data-wrappers/inputs/SupplierSelectDataWrapper';
import { AddPurchaseTicketsForm } from 'src/app/components/forms/purchase/AddPurchaseTicketsForm';
import {
    SelectPurchaseEventForm,
    SelectPurchaseEventFormValues,
} from 'src/app/components/forms/purchase/SelectPurchaseEventForm';
import { useCustomConfirm } from 'src/app/hooks/useCustomConfirm';
import { FormProps } from 'src/app/interfaces/form/formProps';
import calculatedExchangeRate from 'src/app/utilities/helpers/calculatedExchangeRate';
import { formatDecimals } from 'src/app/utilities/helpers/formatDecimals';
import { isCreditCardMethod } from 'src/app/utilities/helpers/paymentMethod';
import currencyOptions from 'src/app/utilities/mappers/mapCurrenciesToOptions';
import { paymentMethodOptions } from 'src/app/utilities/mappers/mapPaymentMethodsToOptions';
import { zodOptionalNumberInputSchema } from 'src/app/utilities/zod/zodOptionalNumberInputSchema';
import { zodRequiredDropdownSelectSchema } from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import { zodRequiredNumberInputSchema } from 'src/app/utilities/zod/zodRequiredNumberInputSchema';
import { PURCHASE_TYPES, PURCHASE_TYPE_ITEMS } from 'src/data/enums/purchase';
import { defaultCurrency } from 'src/shared/currencies';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import { AutoCompleteOption } from 'src/view/components/auto-complete/interfaces';
import Button from 'src/view/components/button/Button';
import Checkbox from 'src/view/components/checkbox/Checkbox';
import DatePicker from 'src/view/components/date-picker/DatePicker';
import { Divider } from 'src/view/components/divider/Divider';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import Modal, { ModalBody } from 'src/view/components/modal/Modal';
import TableHeader from 'src/view/components/table/table-header/TableHeader';
import z from 'zod';

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing(2),
    },
    priceRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
    priceRowLast: {
        marginTop: theme.spacing(1),
    },
    priceRowText: {
        marginRight: theme.spacing(1),
        flex: 1,
        fontWeight: 'bold',
    },
    priceRowTextWithInput: {
        paddingTop: 13,
    },
    rowSubText: {
        color: theme.colors.darkGrey,
        fontSize: '0.7rem',
        fontWeight: 'bold',
    },
    priceDivider: {
        height: 1,
        width: '100%',
        background: theme.palette.primary.main,
    },
    priceInputWrapper: {
        flexGrow: 0,
        flexShrink: 0,
        width: 166,
    },
    totalTicketsValue: {
        fontWeight: 'bold',
    },
    redirectionOptionItem: {
        background: 'white',
        borderRadius: theme.layout.borderRadius.regular,
    },
    todaysDateWrapper: {
        marginTop: theme.spacing(1),
    },
    todaysDate: {
        color: theme.colors.linkBlue,
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    defaultCurrencyPrice: {
        color: theme.colors.grey,
        fontSize: '0.875rem',
    },
}));

interface PurchaseEventTicketInfo {
    quantity: number;
    seatingplanCategory: AutoCompleteOption | null;
    pricePerTicket: string | number;
    ticketsPerSplit?: number | string;
}

export interface PurchaseEvent {
    event: AutoCompleteOption;
    ticketInfo?: PurchaseEventTicketInfo[];
}

export interface CreatePurchaseFormValues {
    supplier: AutoCompleteOption;
    supplierAccountUsername?: string;
    purchaseDate: Date;
    currency: AutoCompleteOption;
    externalReference?: string;
    paymentMethod?: AutoCompleteOption;
    creditcardNumber?: string;
    internalNotes?: string;
    events: PurchaseEvent[];
    additionalCharges?: string;
    totalPurchasePrice: string;
    foreignPriceExchangeRate: string;
    finalized?: boolean;
    purchaseType?: AutoCompleteOption;
}

const createPurchaseValidationSchema = z
    .object({
        supplier: zodRequiredDropdownSelectSchema,
        supplierUserAccount: z.string().optional(),
        purchaseDate: z.instanceof(Date).refine((val) => moment(val).isBefore(), {
            message: 'Future date is not allowed.',
        }),
        currency: zodRequiredDropdownSelectSchema,
        foreignPriceExchangeRate: zodOptionalNumberInputSchema
            .nullish()
            .transform((val) => (val ? Number(val) : undefined)),
        externalReference: z.string().optional(),
        paymentMethod: zodRequiredDropdownSelectSchema.nullable().optional(),
        creditcardNumber: z.string().optional(),
        internalNotes: z.string().optional(),
        finalized: z.boolean().optional(),
        purchaseType: zodRequiredDropdownSelectSchema,
        events: z.array(
            z.object({
                event: zodRequiredDropdownSelectSchema,
                ticketInfo: z.array(
                    z
                        .object({
                            seatingplanCategory: zodRequiredDropdownSelectSchema.superRefine(
                                (val, ctx) => {
                                    if (!val?.value) {
                                        ctx.addIssue({
                                            code: z.ZodIssueCode.custom,
                                            message: 'This field is needed!',
                                            path: ['seatingplanCategory'],
                                        });
                                    }
                                }
                            ),
                            quantity: zodRequiredNumberInputSchema,
                            pricePerTicket: zodRequiredNumberInputSchema,
                            ticketsPerSplit: zodOptionalNumberInputSchema.refine(
                                (val) => (val ? val !== 1 : true),
                                {
                                    message: 'Split of 1 is not allowed',
                                }
                            ),
                        })
                        .required()
                        .superRefine((scheme, ctx) => {
                            if (
                                !!scheme.ticketsPerSplit &&
                                scheme.quantity % Number(scheme.ticketsPerSplit) !== 0
                            ) {
                                ctx.addIssue({
                                    code: z.ZodIssueCode.custom,
                                    message: 'Quantity should be dividable by tickets per split',
                                    path: ['ticketsPerSplit'],
                                });
                            }
                        })
                ),
            })
        ),
        additionalCharges: zodOptionalNumberInputSchema,
        totalPurchasePrice: zodOptionalNumberInputSchema,
    })
    .superRefine((schema, ctx) => {
        // Credit card number is required if payment method is credit card
        const isCreditCardNumberMissing =
            schema.paymentMethod && isCreditCardMethod(schema?.paymentMethod?.label);

        if (isCreditCardNumberMissing) {
            if (schema.creditcardNumber?.length !== 4) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Please enter 4 digits',
                    path: ['creditcardNumber'],
                });
            }
        }

        // Exchange rate is required if the currency is different than the default currency
        const isExchangeRateMissing =
            schema.currency.value !== defaultCurrency && !schema.foreignPriceExchangeRate;
        if (isExchangeRateMissing) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Required if the Currency is different than the default currency - ${defaultCurrency}`,
                path: ['foreignPriceExchangeRate'],
            });
        }

        const additionalCharges = new BigNumber(schema.additionalCharges || 0).toNumber();
        const sumOfTicketsAndAdditionalCharges = new BigNumber(
            calculateTicketsTotal(schema.events)
        ).plus(additionalCharges);
        const isTotalCorrect = new BigNumber(schema.totalPurchasePrice || 0).eq(
            sumOfTicketsAndAdditionalCharges
        );

        if (!isTotalCorrect) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'The purchase total is not the same as tickets total + additional charges',
                path: ['totalPurchasePrice'],
            });
        }
    });

export const CreatePurchaseForm = ({
    defaultValues,
    values,
    onSubmit,
    loading,
}: FormProps<CreatePurchaseFormValues>) => {
    const classes = useStyles();
    const theme = useTheme();
    const confirm = useCustomConfirm();
    const spacingStyles = useSpacingStyles();
    const [selectEventOpen, setSelectEventOpen] = useState(false);
    const [eventsError, setEventsError] = useState<string>();

    const { control, reset, setValue, watch, handleSubmit, getValues, clearErrors, trigger } =
        useForm<CreatePurchaseFormValues>({
            mode: 'onSubmit',
            defaultValues: { currency: currencyOptions[0], ...defaultValues },
            resolver: zodResolver(createPurchaseValidationSchema),
        });

    const { fields, remove, append } = useFieldArray({
        control,
        name: 'events',
    });

    const paymentMethod = watch('paymentMethod');
    const currency = watch('currency');
    const events = useWatch({ control, name: 'events' });
    const additionalCharges = watch('additionalCharges');
    const foreignPriceExchangeRate = watch('foreignPriceExchangeRate');

    useEffect(() => {
        if (!values || isEqual(values, getValues())) return;

        reset(values);
    }, [values]);

    const datePickerOnChange = (value: Moment | null): void => {
        if (value === null) {
            setValue('purchaseDate', moment().toDate(), {
                shouldValidate: true,
            });
            return;
        }

        setValue('purchaseDate', value.toDate(), {
            shouldValidate: true,
        });
    };

    const handleAddEvent = (values: SelectPurchaseEventFormValues) => {
        append({
            event: values.event,
        });

        setSelectEventOpen(false);
    };

    const handleSubmitCreatePurchase = () => {
        if (events.length === 0) {
            return setEventsError('Select a minimum of 1 event');
        }

        const eventWithZeroTickets = events.some(
            (e) => !e.ticketInfo || (e.ticketInfo && e.ticketInfo.length === 0)
        );

        if (eventWithZeroTickets) {
            return setEventsError('Remove the event without any tickets');
        }

        handleSubmit((values) => {
            setEventsError(undefined);
            onSubmit(values);
        })();
    };
    useEffect(() => {
        // Clear the total purchase price error when additional charges and events(tickets) are updated
        // Only trigger errors on Submit
        clearErrors('totalPurchasePrice');
    }, [events, additionalCharges]);

    const totalPriceInDefaultCurrency = useMemo(() => {
        return `${calculatedExchangeRate(
            calculateTicketsTotal(events),
            foreignPriceExchangeRate || 1
        )} ${defaultCurrency}`;
    }, [foreignPriceExchangeRate, events, defaultCurrency]);

    const isForeignCurrencySelected = currency.value !== defaultCurrency ? true : false;

    return (
        <>
            <Grid container className={classes.container}>
                <Grid container item spacing={2} className={classes.container} lg={4}>
                    <FormItem>
                        <Grid item xs={12}>
                            <h3 className={classnames(spacingStyles.noMarginBottom)}>Details</h3>
                        </Grid>
                    </FormItem>

                    <FormItem>
                        <FormLabel>Supplier*</FormLabel>
                        <Controller
                            name="supplier"
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => {
                                return (
                                    <>
                                        <SupplierSelectDataWrapper
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                            onSuccessfullyCreate={(option) => onChange(option)}
                                        />
                                        <FormFieldError message={error?.message} />
                                    </>
                                );
                            }}
                        />
                    </FormItem>

                    <FormItem>
                        <FormLabel>Supplier account username</FormLabel>
                        <Controller
                            name="supplierAccountUsername"
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => {
                                return (
                                    <>
                                        <Input
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                            placeholder="Enter a supplier account username"
                                        />
                                        <FormFieldError message={error?.message} />
                                    </>
                                );
                            }}
                        />
                    </FormItem>
                    <FormItem>
                        <FormLabel>Purchase date*</FormLabel>
                        <Controller
                            name="purchaseDate"
                            control={control}
                            render={({ field: { value }, fieldState: { error } }) => (
                                <>
                                    <DatePicker
                                        placeholder="Purchase date"
                                        value={value || null}
                                        onChange={datePickerOnChange}
                                        maxDate={moment(Date.now())}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                        <div className={classes.todaysDateWrapper}>
                            <span
                                className={classes.todaysDate}
                                onClick={() =>
                                    setValue('purchaseDate', moment().toDate(), {
                                        shouldValidate: true,
                                    })
                                }
                            >
                                Set to today
                            </span>
                        </div>
                    </FormItem>

                    <FormItem>
                        <FormLabel>Currency*</FormLabel>
                        <Controller
                            name="currency"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <>
                                    <AutoComplete
                                        name={name}
                                        placeholder="Select a currency"
                                        value={value || null}
                                        onChange={onChange}
                                        options={currencyOptions}
                                        isClearable={false}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </FormItem>

                    {isForeignCurrencySelected && (
                        <>
                            <FormItem>
                                <FormLabel>Conversion rate*</FormLabel>
                                <Controller
                                    name="foreignPriceExchangeRate"
                                    control={control}
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            <Input
                                                name={name}
                                                onChange={onChange}
                                                value={value}
                                                placeholder="Enter exchange rate"
                                                type="number"
                                            />
                                            <FormFieldError message={error?.message} />
                                        </>
                                    )}
                                />
                            </FormItem>
                        </>
                    )}

                    <FormItem>
                        <FormLabel>External Reference</FormLabel>
                        <Controller
                            name="externalReference"
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => {
                                return (
                                    <>
                                        <Input
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                            placeholder="Enter a external reference"
                                        />
                                        <FormFieldError message={error?.message} />
                                    </>
                                );
                            }}
                        />
                    </FormItem>
                    <FormItem>
                        <FormLabel>Payment Method</FormLabel>
                        <Controller
                            name="paymentMethod"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <>
                                    <AutoComplete
                                        name={name}
                                        placeholder="Select a payment method"
                                        value={value || null}
                                        onChange={onChange}
                                        options={paymentMethodOptions}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </FormItem>
                    {paymentMethod && isCreditCardMethod(paymentMethod.value) && (
                        <>
                            <Grid item xs={12} lg={12}>
                                <FormLabel>Creditcard Number (last 4 digits)*</FormLabel>
                                <Controller
                                    name="creditcardNumber"
                                    control={control}
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            <Input
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                placeholder="Enter last 4 digits of creditcard"
                                            />
                                            <FormFieldError message={error?.message} />
                                        </>
                                    )}
                                />
                            </Grid>
                        </>
                    )}

                    <FormItem>
                        <FormLabel>Internal notes</FormLabel>
                        <Controller
                            name="internalNotes"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <>
                                    <Input
                                        multiline
                                        maxRows={5}
                                        minRows={5}
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Enter internal notes"
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </FormItem>

                    <FormItem>
                        <FormLabel>Purchase Type*</FormLabel>
                        <Controller
                            name="purchaseType"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <>
                                    <AutoComplete
                                        name={name}
                                        placeholder="Select a purchase type"
                                        value={value || null}
                                        onChange={onChange}
                                        options={PURCHASE_TYPES.map((pt) => ({
                                            label: PURCHASE_TYPE_ITEMS[pt],
                                            value: pt,
                                        }))}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </FormItem>

                    <FormItem>
                        <Controller
                            name="finalized"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <>
                                    <FormLabel>
                                        Mark as finalized
                                        <Checkbox
                                            color="primary"
                                            name={name}
                                            checked={value || false}
                                            onChange={onChange}
                                        />
                                    </FormLabel>

                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </FormItem>
                </Grid>

                <Grid container item lg={12} spacing={2} marginBottom={2}>
                    <Grid item xs={12} lg={4}>
                        <Divider variant="dark" marginTop={0} marginBottom={0} />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <h3
                            className={classnames(
                                spacingStyles.noMarginBottom,
                                spacingStyles.noMarginTop
                            )}
                        >
                            Tickets
                        </h3>
                    </Grid>

                    {fields.map((event, i) => (
                        <React.Fragment key={event.id}>
                            <Grid item xs={12} lg={8}>
                                <Controller
                                    name={`events.${i}`}
                                    control={control}
                                    render={({ field: { value } }) => {
                                        return <TableHeader title={value.event.label} />;
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <AddPurchaseTicketsForm
                                    control={control}
                                    eventIndex={i}
                                    eventId={event.event.value}
                                    currency={currency.value}
                                    exchangeRate={foreignPriceExchangeRate}
                                    onClickDeleteEvent={async () => {
                                        const hasAcceptedDelete = await confirm({
                                            title: 'Are you sure you want to delete this ticket?',
                                        });

                                        if (hasAcceptedDelete) {
                                            remove(i);
                                        }
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    ))}
                    <FormItem>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setSelectEventOpen(true);
                                setEventsError(undefined);
                            }}
                        >
                            Add Event
                        </Button>
                    </FormItem>
                    {eventsError && (
                        <>
                            <FormItem>
                                <FormFieldError message={eventsError} />
                            </FormItem>
                        </>
                    )}
                </Grid>

                <Grid container item lg={4} spacing={2}>
                    <FormItem>
                        <Divider variant="dark" marginTop={0} marginBottom={0} />
                    </FormItem>
                    <FormItem>
                        <h3
                            className={classnames(
                                spacingStyles.noMarginBottom,
                                spacingStyles.noMarginTop
                            )}
                        >
                            Prices
                        </h3>
                    </FormItem>
                    <FormItem>
                        <div className={classes.priceRow}>
                            <div className={classes.priceRowText}>
                                <span>Total for all tickets</span>
                            </div>
                            <div>
                                <span className={classes.totalTicketsValue}>
                                    {calculateTicketsTotal(events)} {currency.value}
                                    <br />
                                    {isForeignCurrencySelected && (
                                        <span className={classes.defaultCurrencyPrice}>
                                            {totalPriceInDefaultCurrency}
                                        </span>
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className={classnames(classes.priceRow)}>
                            <div
                                className={classnames(
                                    classes.priceRowText,
                                    classes.priceRowTextWithInput
                                )}
                            >
                                <span>Additional charges / fee(s)</span>
                            </div>
                            <div className={classes.priceInputWrapper}>
                                <Controller
                                    name="additionalCharges"
                                    control={control}
                                    render={({
                                        field: { name, onChange, value },
                                        fieldState: { error },
                                    }) => {
                                        return (
                                            <>
                                                <Input
                                                    type="number"
                                                    name={name}
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={`0.00 ${currency.value}`}
                                                    size="medium"
                                                    greyInputHelperText={
                                                        isForeignCurrencySelected
                                                            ? `${calculatedExchangeRate(
                                                                  value || 0,
                                                                  foreignPriceExchangeRate
                                                              )} ${defaultCurrency}`
                                                            : ''
                                                    }
                                                />
                                                <FormFieldError message={error?.message} />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className={classes.priceDivider} />
                        <div className={classnames(classes.priceRow, classes.priceRowLast)}>
                            <div className={classes.priceRowTextWithInput}>
                                <span className={classes.priceRowText}>Total purchase price</span>
                                <br />
                                <span className={classes.rowSubText}>
                                    The amount stated on the invoice
                                </span>
                            </div>
                            <div className={classes.priceInputWrapper}>
                                <Controller
                                    name="totalPurchasePrice"
                                    control={control}
                                    render={({
                                        field: { name, onChange, value },
                                        fieldState: { error },
                                    }) => {
                                        return (
                                            <>
                                                <Input
                                                    type="number"
                                                    name={name}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        if (additionalCharges) {
                                                            trigger('totalPurchasePrice');
                                                        }
                                                    }}
                                                    value={value}
                                                    placeholder={`0.00 ${currency.value}`}
                                                    size="medium"
                                                    greyInputHelperText={
                                                        isForeignCurrencySelected
                                                            ? `${calculatedExchangeRate(
                                                                  value || 0,
                                                                  foreignPriceExchangeRate
                                                              )} ${defaultCurrency}`
                                                            : ''
                                                    }
                                                />
                                                <FormFieldError message={error?.message} />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </FormItem>
                    <FormItem>
                        <FormButtons
                            buttons={[
                                {
                                    children: 'Create Purchase',
                                    onClick: handleSubmitCreatePurchase,
                                    disabled: loading,
                                    startIcon: loading && (
                                        <CircularProgress size={theme.layout.loader.sizes.small} />
                                    ),
                                },
                            ]}
                        />
                    </FormItem>
                </Grid>
            </Grid>

            {selectEventOpen && (
                <EventSelectionModal
                    events={events}
                    setSelectEventOpen={setSelectEventOpen}
                    handleAddEvent={handleAddEvent}
                />
            )}
        </>
    );
};

const FormItem = ({ children }: React.PropsWithChildren) => {
    return (
        <Grid item xs={12} lg={12}>
            {children}
        </Grid>
    );
};

const calculateEventTotalTicketsPrice = (event: PurchaseEvent) => {
    return sumBy(event.ticketInfo, (ticketInfo) => {
        const price = new BigNumber(ticketInfo.pricePerTicket);

        if (price.isNaN()) return 0;

        const total = price.multipliedBy(ticketInfo.quantity);

        return total.toNumber();
    });
};

const calculateTicketsTotal = (events: PurchaseEvent[] = []) => {
    const totalTicketsPrice = events.reduce(
        (total, event) => total + calculateEventTotalTicketsPrice(event),
        0
    );

    return formatDecimals(totalTicketsPrice);
};

const EventSelectionModal = ({
    setSelectEventOpen,
    handleAddEvent,
    events,
}: {
    setSelectEventOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleAddEvent: (event: PurchaseEvent) => void;
    events: PurchaseEvent[];
}) => {
    return (
        <Modal open={true} title="Add Event" onClose={() => setSelectEventOpen(false)}>
            <ModalBody>
                <SelectPurchaseEventForm
                    onSubmit={(values) => handleAddEvent(values)}
                    events={events}
                />
            </ModalBody>
        </Modal>
    );
};
