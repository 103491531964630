import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Price from 'src/data/models/common/price';
import { defaultCurrency } from 'src/shared/currencies';
import { formatPrice } from 'src/shared/helpers/formatPrice';
import { Divider } from 'src/view/components/divider/Divider';
import Popover from 'src/view/components/popover/Popover';
import { Price as PriceComponent } from 'src/view/components/price/Price';

interface TotalPriceDifferencePopoverProps {
    text: string | number | JSX.Element;
    ticketsTotalOriginal: Price | null;
    purchaseTotalOriginal: Price | null;
    ticketsTotalEuro: Price | null;
    purchaseTotalEuro: Price | null;
}

export const TotalPriceDifferencePopover = ({
    text,
    ticketsTotalOriginal,
    purchaseTotalOriginal,
    ticketsTotalEuro,
    purchaseTotalEuro,
}: TotalPriceDifferencePopoverProps) => {
    const currency = purchaseTotalEuro?.currency || purchaseTotalOriginal?.currency;
    const isForeignCurrencyPurchase =
        purchaseTotalOriginal && purchaseTotalOriginal?.currency !== defaultCurrency;
    const ticketsPriceOriginal = ticketsTotalOriginal?.value || 0;
    const purchasePriceOriginal = purchaseTotalOriginal?.value || 0;
    const ticketsPriceEuro = ticketsTotalEuro?.value || ticketsPriceOriginal || 0;
    const purchasePriceEuro = purchaseTotalEuro?.value || purchasePriceOriginal || 0;

    return (
        <Popover text={text} width={350} boxPadding={0}>
            <Box sx={{ padding: (theme) => theme.spacing(1) }}>
                <Box
                    component="p"
                    sx={{
                        marginTop: 0,
                        fontSize: '1rem',
                    }}
                >
                    The purchase prices of the tickets are different than the total purchase price
                </Box>
                <Box
                    sx={(theme) => ({
                        background: theme.colors.lightGrey,
                        padding: theme.spacing(1),
                        borderRadius: theme.layout.borderRadius.regular,
                    })}
                >
                    <Section>
                        <div>Total purchase price</div>
                        <PriceContainer>
                            {formatPrice({
                                currency,
                                amount: purchasePriceOriginal || 0,
                            })}
                            {isForeignCurrencyPurchase && <Euro price={purchasePriceEuro} />}
                        </PriceContainer>
                    </Section>
                    <Section>
                        <div>Tickets purchase prices</div>
                        <PriceContainer>
                            {formatPrice({
                                currency,
                                amount: ticketsPriceOriginal || 0,
                            })}
                            {isForeignCurrencyPurchase && <Euro price={ticketsPriceEuro} />}
                        </PriceContainer>
                    </Section>

                    <Divider />

                    <Section
                        sx={(theme) => ({
                            color: theme.palette.error.main,
                        })}
                    >
                        <div>Difference</div>
                        <PriceContainer>
                            {formatPrice({
                                currency,
                                amount: purchasePriceOriginal - ticketsPriceOriginal,
                            })}
                            {isForeignCurrencyPurchase && (
                                <Euro price={purchasePriceEuro - ticketsPriceEuro} />
                            )}
                        </PriceContainer>
                    </Section>
                </Box>
            </Box>
        </Popover>
    );
};

const Section = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
});

const PriceContainer = styled(Box)({ textAlign: 'right', marginBottom: '0.6rem' });

function Euro({ price }: { price: number }) {
    return (
        <PriceComponent
            price={{ currency: defaultCurrency, value: price }}
            grey
            sx={(theme) => ({
                fontSize: '0.8rem',
                textAlign: 'right',
                marginLeft: theme.spacing(1),
            })}
        />
    );
}
