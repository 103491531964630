import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import AddPurchaseTicketsModalFeature from 'src/app/components/features/purchase/AddPurchaseTicketsModalFeature';
import DeletePurchaseTicketsModalFeature from 'src/app/components/features/purchase/DeletePurchaseTicketsModalFeature';
import {
    EditPurchasePriceFormValuesProps,
    PurchasePricesPerCategory,
} from 'src/app/components/forms/purchase/EditPurchasePricesForm';
import TicketsTable, { TicketsTableDataResolver } from 'src/app/components/tables/TicketsTable';
import { config } from 'src/app/constants/config/config';
import useQueryParams from 'src/app/hooks/useQueryParams';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import tableSortingToSortingOptionMapper from 'src/app/utilities/mappers/table/tableSortingToSortingOptionMapper';
import FilterOption from 'src/data/api/common/FilterOption';
import purchaseService from 'src/data/services/purchaseService';
import Button from 'src/view/components/button/Button';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import { TableAction } from 'src/view/components/table-toolbar/TableToolbar';
import TableHeader from 'src/view/components/table/table-header/TableHeader';
import { RowIdResolver } from 'src/view/components/table/table/Table';
import AdvancedEditPurchasesFilterForm, {
    useAdvancedEditPurchaseFilters,
} from '../../forms/AdvancedEditPurchasesFilterForm';
import PostEditPurchaseFollowUpModal from './PostEditPurchaseFollowUpModal';

interface PurchaseTicketsTableFeatureProps {
    purchaseId: string;
    purchasePrices: PurchasePricesPerCategory[];
}
export const PURCHASE_TICKETS_QUERY = 'PURCHASE_TICKETS';

const PurchaseTicketsTableFeature = ({
    purchaseId,
    purchasePrices,
}: PurchaseTicketsTableFeatureProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isAddTicketsModalOpen, setIsAddTicketsModalOpen] = useState(false);
    const [isFollowUpModalOpen, setFollowUpModalOpen] = useState(false);
    const [followUpModalData, setFollowUpModalData] = useState<
        EditPurchasePriceFormValuesProps[] | null
    >([]);

    const [selectedTickets, setSelectedTickets] = useState<
        RowIdResolver<TicketsTableDataResolver>[]
    >([]);

    const {
        values: { sortingOptions, filterOptions },
        setSortingOptions,
        setFilterOptions,
    } = useQueryParams('purchased_tickets', {
        defaultFilters: [
            {
                property: 'purchaseStatus',
                value: 'Completed,Confirmed',
            },
        ],
    });

    const sorting = sortingOptions.map(tableSortingToSortingOptionMapper);

    const {
        data: purchaseTicketsData,
        isLoading,
        isFetching,
        isError,
        error,
    } = useQuery({
        queryKey: [PURCHASE_TICKETS_QUERY, purchaseId, currentPage, sorting, filterOptions],
        queryFn: async () =>
            purchaseService.fetchPurchaseTickets(purchaseId, {
                page: currentPage,
                pageSize: config.ITEMS_PER_PAGE_STANDARD,
                sorting,
                filter: filterOptions,
            }),
        enabled: !!purchaseId,
    });

    const deleteTicketsToolbarAction = (): TableAction => {
        return {
            hidden: false,
            disabled: !selectedTickets.length,
            label: 'Delete Tickets',
            position: 'left',
            color: 'error',
            callback: () => {
                setIsDeleteModalOpen(true);
            },
        };
    };

    const handleSuccessfulTicketDeletion = useCallback(
        (followUpModalData: EditPurchasePriceFormValuesProps[]) => {
            setSelectedTickets([]);
            setFollowUpModalData(followUpModalData);
            setFollowUpModalOpen(true);
        },
        []
    );

    const handleTicketAddSuccess = useCallback(
        (followUpModalData: EditPurchasePriceFormValuesProps[]) => {
            setFollowUpModalData(followUpModalData);
            setFollowUpModalOpen(true);
        },
        []
    );

    const {
        defaultValues,
        hasFinishedFormingDefaultValues,
        eventsDropdownOptions,
        seatingPlanCategoriesDropdownOptions,
    } = useAdvancedEditPurchaseFilters({
        eventId: filterOptions.find((f) => f.property === 'eventId')?.value,
        filterOptions,
        purchaseId,
    });

    const handleChangeFilterOptions = useCallback((options: FilterOption[]) => {
        setCurrentPage(1);
        setFilterOptions(options);
    }, []);

    return (
        <>
            {isLoading && <LoadingOverlay />}
            {isError && <ErrorsList errors={parseErrors(error)} />}

            {/* Table content */}
            <>
                <TableHeader title="Purchase tickets" />

                {hasFinishedFormingDefaultValues && (
                    <AdvancedEditPurchasesFilterForm
                        defaultValues={defaultValues}
                        onChangeFilterOptions={handleChangeFilterOptions}
                        seatingPlanCategoriesDropdownOptions={seatingPlanCategoriesDropdownOptions}
                        eventsDropdownOptions={eventsDropdownOptions}
                    />
                )}
                <Button
                    sx={{
                        marginBottom: purchaseTicketsData?.data.data.length ? 0 : 1,
                    }}
                    onClick={() => setIsAddTicketsModalOpen(true)}
                    color="primary"
                >
                    Add Tickets
                </Button>

                <TicketsTable
                    tickets={purchaseTicketsData?.data.data}
                    loading={isLoading || isFetching}
                    displayForeignPrice
                    pagination={{
                        currentPage: purchaseTicketsData?.data.meta.currentPage || 1,
                        totalPages: purchaseTicketsData?.data.meta.totalPages || 1,
                        onPaginate: (page: number) => {
                            if (currentPage !== page) {
                                setCurrentPage(page);
                            }
                        },
                    }}
                    initialSelectedRows={selectedTickets}
                    onChangeSelectedRows={(selectedRows) => {
                        setSelectedTickets(selectedRows);
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    tableToolbarActions={(_) => [deleteTicketsToolbarAction()]}
                    onChangeSorting={setSortingOptions}
                    includeEventName
                    includePurchaseStatistics
                />
            </>

            {/* Modals */}
            {isDeleteModalOpen && (
                <DeletePurchaseTicketsModalFeature
                    purchasePrices={purchasePrices}
                    purchaseId={purchaseId}
                    selectedTickets={selectedTickets}
                    onCloseModal={() => setIsDeleteModalOpen(false)}
                    onSuccessfulDeletion={handleSuccessfulTicketDeletion}
                />
            )}

            {isAddTicketsModalOpen && (
                <AddPurchaseTicketsModalFeature
                    purchasePrices={purchasePrices}
                    purchaseId={purchaseId}
                    onSuccessfulAddTickets={handleTicketAddSuccess}
                    onCloseModal={() => setIsAddTicketsModalOpen(false)}
                />
            )}

            {isFollowUpModalOpen && followUpModalData && (
                <PostEditPurchaseFollowUpModal
                    seatingPlanCategories={followUpModalData}
                    purchaseId={purchaseId}
                    isOpen={isFollowUpModalOpen}
                    onCloseModal={() => {
                        setFollowUpModalData(null);
                        setFollowUpModalOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default PurchaseTicketsTableFeature;
