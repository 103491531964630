import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BigNumber from 'bignumber.js';
import { sumBy } from 'lodash';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodOptionalNumberInputSchema } from 'src/app/utilities/zod/zodOptionalNumberInputSchema';
import Price from 'src/data/models/common/price';
import Button from 'src/view/components/button/Button';
import { Divider } from 'src/view/components/divider/Divider';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import z from 'zod';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
    },
    header: {
        textAlign: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    boldContainer: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    totalPurchasePrice: {
        fontWeight: 'bold',
    },
    categoryHeader: {
        textAlign: 'left',
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
    priceRow: {
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        textAlign: 'center',
    },
    calculationExplanation: {
        fontSize: '0.8rem',
        color: theme.colors.grey,
    },
}));

const editPurchasePricesValidationSchema = z.object({
    seatingPlanCategoryPrices: z.array(
        z.object({
            pricePerTicket: zodOptionalNumberInputSchema,
            eventId: z.string().min(1),
            eventName: z.string().min(1),
            seatingPlanCategoryId: z.string().min(1),
            quantity: z.number(),
        })
    ),
});

export interface EditPurchasePriceFormValuesProps {
    pricePerTicket: number;
    quantity: number;
    eventId: string;
    seatingPlanCategoryId: string;
    eventName: string;
}

export interface EditPurchaseFormValues {
    seatingPlanCategoryPrices: EditPurchasePriceFormValuesProps[];
}

export interface EditPurchasePricesFormSubmitValues {
    purchaseOriginalPrice: number;
    seatingPlanCategoryPrices: EditPurchasePriceFormValuesProps[];
}

export interface PurchasePricesPerCategory {
    eventId: string;
    eventName: string;
    originalPrice: Price;
    quantity: number;
    seatingPlanCategoryId: string;
    seatingPlanCategoryName: string;
    ticketsPerSplit?: number;
    calculation?: string | null;
}

interface EditPurchasePricesFormProps {
    purchasePrices: PurchasePricesPerCategory[];
    onSubmit: (values: EditPurchasePricesFormSubmitValues) => void;
    isSubmitDisabled?: boolean;
    isLoading?: boolean;
}

const EditPurchasePricesForm = ({
    purchasePrices,
    onSubmit,
    isSubmitDisabled,
    isLoading,
}: EditPurchasePricesFormProps) => {
    const classes = useStyles();

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<EditPurchaseFormValues>({
        resolver: zodResolver(editPurchasePricesValidationSchema),
    });
    const { fields } = useFieldArray({
        control,
        name: 'seatingPlanCategoryPrices',
    });
    const seatingPlanCategoryPrices = watch('seatingPlanCategoryPrices');

    function updateFormValues() {
        setValue(
            'seatingPlanCategoryPrices',
            purchasePrices?.map((price) => ({
                pricePerTicket: Number(price.originalPrice.value),
                quantity: price.quantity,
                eventId: price.eventId,
                seatingPlanCategoryId: price.seatingPlanCategoryId,
                eventName: price.eventName,
            }))
        );
    }

    useEffect(() => {
        updateFormValues();
    }, [purchasePrices]);

    const onHandleSubmit = (values: EditPurchaseFormValues) => {
        onSubmit({
            purchaseOriginalPrice: calculateEditedTotalPurchasePrice(seatingPlanCategoryPrices),
            ...values,
        });
    };

    return (
        <Grid container item className={classes.container} marginBottom={2}>
            {isLoading && <LoadingOverlay />}
            <Grid container item columnSpacing={2} className={classes.header}>
                <Grid item xs={2} className={classes.categoryHeader} lg={3} md={3}>
                    <p>Event</p>
                </Grid>
                <Grid item xs={2} className={classes.categoryHeader} lg={3} md={3}>
                    <p>Category</p>
                </Grid>
                <Grid item xs={2} lg={2} md={3}>
                    <p>Price</p>
                </Grid>
                <Grid item xs={2} lg={2} md={3}>
                    <p># Tickets</p>
                </Grid>
                <Grid item xs={2} lg={2} md={3}>
                    <p>Total</p>
                </Grid>
            </Grid>

            {fields?.map((price, index) => (
                <Grid container key={price.id} className={classes.priceRow} spacing={2}>
                    <Grid lg={3} padding="0" item xs={3} className={classes.categoryHeader}>
                        {purchasePrices[index]?.eventName}
                    </Grid>
                    <Grid lg={3} padding="0" item xs={3} className={classes.categoryHeader}>
                        {purchasePrices[index]?.seatingPlanCategoryName}
                    </Grid>
                    <Grid lg={2} md={3} item xs={2} alignContent="center">
                        <Controller
                            name={`seatingPlanCategoryPrices.${index}.pricePerTicket`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <>
                                    <Input
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Price per ticket"
                                        type="number"
                                    />
                                    <FormFieldError
                                        message={
                                            errors?.seatingPlanCategoryPrices?.[index]
                                                ?.pricePerTicket?.message
                                        }
                                    />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid lg={2} md={3} padding="0" item xs={2}>
                        {purchasePrices[index]?.quantity}{' '}
                        <span className={classes.calculationExplanation}>
                            {purchasePrices[index]?.calculation
                                ? `(${purchasePrices[index]?.calculation})`
                                : ''}
                        </span>
                    </Grid>
                    <Grid lg={2} md={3} padding="0" item xs={2}>
                        {calculateTotalPricePerCategory(
                            purchasePrices[index]?.quantity,
                            seatingPlanCategoryPrices[index].pricePerTicket
                        ).toFixed(2)}
                    </Grid>
                </Grid>
            ))}
            <Divider />
            <Grid item container className={classes.boldContainer} direction="column">
                <Grid item>
                    <p className={classes.totalPurchasePrice}>
                        Total Purchase Price:{' '}
                        {calculateEditedTotalPurchasePrice(seatingPlanCategoryPrices)}
                    </p>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.submitButton}
                        disabled={isSubmitDisabled}
                        onClick={handleSubmit(onHandleSubmit)}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EditPurchasePricesForm;

const calculateTotalPricePerCategory = (quantity: number, price = 0) => {
    const pricePerTicket = new BigNumber(price);

    if (pricePerTicket.isNaN()) return 0;

    const totalPerCategory = pricePerTicket.multipliedBy(quantity);

    return totalPerCategory.toNumber();
};

const calculateEditedTotalPurchasePrice = (formValues: EditPurchasePriceFormValuesProps[]) => {
    const finalPrice = sumBy(formValues, (element) =>
        calculateTotalPricePerCategory(element.quantity, element.pricePerTicket)
    );

    return Number(finalPrice.toFixed(2));
};
