import * as React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Moment } from 'moment';
import Input from 'src/view/components/input/Input';

const useStyles = makeStyles((theme: Theme) => ({
    datePickerContainer: {
        width: '100%',
        backgroundColor: theme.colors.lightestGrey,
        margin: 0,
        '& .MuiOutlinedInput-root': {
            paddingRight: 0,
        },
        '& .MuiFormHelperText-root': {
            backgroundColor: 'white',
            margin: 0,
            paddingTop: 0,
        },
    },
    input: {
        padding: theme.spacing(1.5),
        width: '100%',
        height: 'inherit',
        fontSize: theme.typography.pxToRem(14),
    },
}));

export interface DateTimePickerProps {
    value: Moment | string;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    disabled?: boolean;
    minDate?: string;
    disableManualInput?: boolean;
}

export default function DateTimePicker({
    value,
    onChange,
    disabled,
    minDate,
    disableManualInput,
    ...props
}: DateTimePickerProps): JSX.Element {
    const classes = useStyles();

    return (
        <Input
            type="datetime-local"
            disabled={disabled}
            value={value}
            onChange={onChange}
            className={classes.datePickerContainer}
            inputProps={{
                className: classes.input,
                min: minDate,
            }}
            disableManualInput={disableManualInput}
            {...props}
        />
    );
}
