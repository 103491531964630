import Ticket from 'src/data/models/tickets/ticket';
import { TicketDetailsFormValues } from 'src/app/components/forms/TicketDetailsForm';

export default function mapTicketDataToViewModel(ticket: Ticket): TicketDetailsFormValues {
    const {
        seatingPlanCategoryName,
        seatingPlanCategoryId,
        blockId,
        rowId,
        seatId,
        blockNumber,
        rowNumber,
        seatNumber,
        available,
        files,
        type,
        purchasePrice,
    } = ticket;

    const block =
        blockId && blockNumber
            ? {
                  label: blockNumber,
                  value: blockId,
              }
            : undefined;

    const row =
        rowId && rowNumber
            ? {
                  label: rowNumber,
                  value: rowId,
              }
            : undefined;

    const seat =
        seatId && seatNumber
            ? {
                  label: seatNumber,
                  value: seatId,
              }
            : undefined;

    return {
        category: seatingPlanCategoryName,
        seatingPlanCategoryId,
        block: block,
        row: row,
        seat: seat,
        available: available,
        type: type,
        ticketFile: files[0],
        purchasePrice: purchasePrice ?? undefined,
    };
}
