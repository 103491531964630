import { getInternalAxiosInstance } from 'src/data/api/api';
import CreateDataResponse from 'src/data/api/common/CreateDataResponse';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import { SalesProfile } from 'src/data/models/sales-profile/SalesProfile';
import {
    TICKETS_SALES_CAPS_CREATE_ENDPOINT,
    TICKETS_SALES_CAPS_DELETE_ENDPOINT,
    TICKETS_SALES_CAPS_GET_BY_ID_ENDPOINT,
    TICKETS_SALES_CAPS_UPDATE_ENDPOINT,
} from '../constants/endpoints/tickets-endpoints';
import { Price } from '../models/price/price';

export interface TicketSalesCap {
    eventId: string;
    seatingPlanCategoryId: string;
    seatingPlanCategoryName: string;
    price: Price;
    salesCap: number;
    fixedQuantityStep: number;
    allSalesProfiles: boolean;
    salesProfiles: SalesProfile[];
}

export interface CreateTicketsSalesCapDto {
    seatingPlanCategoryId: string;
    price?: Price;
    salesCap: number;
    fixedQuantityStep: number;
    allSalesProfiles?: boolean;
    salesProfileIds: string[];
}

async function getTicketSalesCapById(
    eventId: string,
    id: string
): Promise<ApiResponse<ApiResponseBody<TicketSalesCap>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<TicketSalesCap>>(TICKETS_SALES_CAPS_GET_BY_ID_ENDPOINT(eventId, id))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function createTicketsSalesCap(
    eventId: string,
    dto: CreateTicketsSalesCapDto
): Promise<ApiResponse<ApiResponseBody<CreateDataResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<CreateDataResponse>>(TICKETS_SALES_CAPS_CREATE_ENDPOINT(eventId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateTicketsSalesCap(
    eventId: string,
    id: string,
    dto: CreateTicketsSalesCapDto
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(TICKETS_SALES_CAPS_UPDATE_ENDPOINT(eventId, id), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function deleteTicketsSalesCap(
    eventId: string,
    id: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(TICKETS_SALES_CAPS_DELETE_ENDPOINT(eventId, id))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    getTicketSalesCapById,
    createTicketsSalesCap,
    deleteTicketsSalesCap,
    updateTicketsSalesCap,
};
