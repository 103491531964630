import Price from 'src/data/models/common/price';
import { SalesProfile } from 'src/data/models/sales-profile/SalesProfile';

export const STATUSES = ['Inactive', 'Active', 'Locked', 'Unlocked', 'Closed'] as const;

export interface Event {
    id: string;
    name: string;
    dateTimeStart: string;
    dateTimeEnd: string | null;
    dateConfirmed: string;
    isPublished: boolean;
    seriesId: string;
    seriesName: string;
    status: (typeof STATUSES)[number];
    venueId: string;
    venueName: string;
    seatingPlanId: string;
    seatingPlanName: string;
    resultsSummary?: ResultsSummary;
    seatingPlanCategoryResultsSummaries: SeatingPlanCategoryResultsSummary[];
}

export interface EventDetails {
    name: string;
    eventType: EVENT_TYPE;
    eventCategory: string;
    eventCategoryId: string;
    dateTimeStart: string;
    dateTimeEnd: string;
    dateConfirmed: boolean;
    description: string;
    homeTeam: Team | null;
    awayTeam: Team | null;
    matches: string[] | null;
    performerNames: string[] | null;
    tags: { id: string; name: string }[];
    organizerId: string;
    organizerName: string;
    seatingPlanId: string;
    seatingPlanName: string;
    seriesId: string;
    seriesName: string;
    venueId: string;
    venueName: string;
    isActive: boolean;
    ticketsSentLatestDays: number;
    ticketsSentEarliestDays: number;
    restrictions: {
        allowAwayFans?: boolean;
        requireFullCustomerAddress?: boolean;
        blacklistedCountryCodes: string[];
        blacklistedNationalityCodes: string[];
    };
    internalNotes: InternalNotesType;
}

export type InternalNotesType = {
    notes: string;
    updatedAt: string;
} | null;

export interface EventDetailsMeta {
    statistics: EventStatistics;
    seatingPlanCategories?: EventSeatingPlanCategory[];
}

export interface EventStatistics {
    ordersAssignedPercentage: number;
    ordersFullyAssigned: number;
    ordersPartiallyPaidPercentage: number;
    ordersPaymentApproved: number;
    ordersPaymentApprovedPercentage: number;
    ordersPaymentPartial: number;
    ordersReady: number;
    ordersReadyPercentage: number;
    ordersSentPercentage: number;
    totalOrderSent: number;
    totalOrders: number;
    ticketsAssigned: number;
    ticketsAssignedPercentage: number;
    ticketsAvailable: number;
    ticketsAvailablePercentage: number;
    ticketsSold: number;
    ticketsSoldPercentage: number;
    ticketsTotal: number;
}

export interface EventSeatingPlanCategory {
    id: string;
    name: string;
    eventId: string;
    eventTicketSalesCaps: EventTicketsSalesCap[];
    ticketsTotal: number;
    ticketsSold: number;
    ticketsAssigned: number;
    ticketsAvailable: number;
    ticketsSoldPercentage: number;
    ticketsAssignedPercentage: number;
    ticketsAvailablePercentage: number;
    averageTicketPricesPerCurrency?: Price[];
    totalTicketPricesPerCurrency?: Price[];
    isArchived?: boolean;
    totalSalesPricesPerCurrency?: Price[];
    averageSalesPricesPerCurrency?: Price[];
    hasSalesPrice?: boolean | null;
    totalSalesPricesAsEuro: Price | null;
    isAssignedDifferently: boolean;
}

export interface EventTicketsSalesCap {
    id: string;
    eventId: string;
    seatingPlanCategoryId: string;
    seatingPlanCategoryName?: string;
    isArchived?: boolean;
    price: Price;
    salesCap: number;
    split: number;
    allCustomers: boolean;
    salesProfiles: SalesProfile[];
}

export enum EVENT_TYPE {
    TeamSports = 'TeamSports',
    Tennis = 'Tennis',
    Concert = 'Concert',
    Sports = 'Sports',
}

export interface SeatingPlanCategoryResultsSummary {
    id: string;
    salesCap: number;
    sales: number;
    tickets: number;
    isArchived: boolean;
}

export interface ResultsSummary {
    ticketsInventoryQuantity: number;
    ticketsSoldQuantity: number;
    ticketsRemainingQuantity: number;
    ticketsNotSent: number;
    ticketsReady: number;
    ticketsSent: number;
}

export interface Team {
    id: string;
    name: string;
    shortName: string;
    abbreviation: string;
    city: string;
    countryCode: string;
    color: Color;
}

export interface Color {
    r: number;
    g: number;
    b: number;
}
