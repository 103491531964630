import compact from 'lodash/compact';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Filters, { FiltersArray } from 'src/app/components/forms/filters/Filters';
import { useFetchPurchasesEvents } from 'src/app/hooks/purchases/useFetchPurchaseFilters';
import { useFetchSeatingPlanCategories } from 'src/app/hooks/useFetchSeatingPlanCategories';
import { purchaseStatusOptions } from 'src/app/utilities/helpers/filter-options/purchase';
import formatValuesToParams from 'src/app/utilities/helpers/formatValuesToParams';
import tableFilterFormHelper from 'src/app/utilities/helpers/tableFilterFormHelper';
import FilterOption from 'src/data/api/common/FilterOption';
import {
    FilterAutoCompleteOption,
    FilterAutoCompleteOptions,
} from 'src/view/components/filters/AutoComplete/AutoComplete';

export interface AdvancedEditPurchasesFilterFormValues {
    purchaseStatus?: FilterAutoCompleteOptions;
    eventId?: FilterAutoCompleteOption;
    seatingPlanCategoryId?: FilterAutoCompleteOptions;
}

interface AdvancedEditPurchasesFilterFormProps {
    defaultValues: AdvancedEditPurchasesFilterFormValues;
    eventsDropdownOptions: FilterAutoCompleteOptions;
    seatingPlanCategoriesDropdownOptions: FilterAutoCompleteOptions;
    onChangeFilterOptions: (options: FilterOption[]) => void;
}

export default function AdvancedEditPurchasesFilterForm({
    defaultValues,
    eventsDropdownOptions,
    seatingPlanCategoriesDropdownOptions,
    onChangeFilterOptions,
}: AdvancedEditPurchasesFilterFormProps): JSX.Element {
    const [urlInvalidatedAt, setUrlInvalidatedAt] = useState<number | undefined>();

    const form = useForm<AdvancedEditPurchasesFilterFormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const { watch, reset, control, resetField, setValue } = form;
    const event = watch('eventId'); // event id has to be single {value: eventId, label: eventName}
    const purchaseStatus = watch('purchaseStatus');

    /** The variable name is kept as seatingPlanCategoryId for convenience of useQueryParams
     * Query params are formed as seatingPlanCategoryId=id1,id2,id3
     * */
    const seatingPlanCategoryId = watch('seatingPlanCategoryId');

    useEffect(() => {
        if (!event) {
            return setValue('seatingPlanCategoryId', []);
        }
        resetField('seatingPlanCategoryId');
    }, [event]);

    useEffect(() => {
        const filters = compact([
            formatValuesToParams.formatOptionsToParam(
                'seatingPlanCategoryId',
                seatingPlanCategoryId
            ),
            formatValuesToParams.formatOptionsToParam('purchaseStatus', purchaseStatus),
            formatValuesToParams.formatOptionToParam('eventId', event),
        ]).filter((f) => f.value !== undefined && f.value !== '');

        onChangeFilterOptions(filters);
    }, [urlInvalidatedAt, JSON.stringify(defaultValues)]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const arrayOfFilters: FiltersArray = [
        {
            type: 'autocomplete',
            options: purchaseStatusOptions,
            name: 'purchaseStatus',
            filterPlaceholderProps: {
                selectedText: 'Status selected',
                placeholder: 'Purchase status',
            },
            onChange: () => {
                setUrlInvalidatedAt(Date.now());
            },
            isMulti: true,
        },
        {
            type: 'autocomplete',
            options: eventsDropdownOptions,
            name: 'eventId',
            filterPlaceholderProps: {
                placeholder: 'Purchase event',
            },
            onChange: () => {
                setUrlInvalidatedAt(Date.now());
            },
        },
        {
            type: 'autocomplete',
            options: seatingPlanCategoriesDropdownOptions,
            name: 'seatingPlanCategoryId',
            filterPlaceholderProps: {
                selectedText: 'Selected category',
                placeholder: 'Seating Plan Category',
            },
            isMulti: true,
            disabled: !event?.value,
            onChange: () => {
                setUrlInvalidatedAt(Date.now());
            },
        },
    ];

    return <Filters control={control} filters={arrayOfFilters} />;
}

const usePurchaseEventsFilters = ({
    filterOptions,
    purchaseId,
}: {
    filterOptions: FilterOption[];
    purchaseId: string;
}) => {
    const { data: purchaseEvents, isLoading } = useFetchPurchasesEvents({
        fetchOptions: {
            filter: [
                {
                    property: 'purchaseId',
                    value: purchaseId,
                },
            ],
        },
    });

    const eventsDropdownOptions = useMemo(
        () =>
            (purchaseEvents?.data?.data || []).map(({ eventId, eventName }) => ({
                value: eventId,
                label: eventName,
            })),
        [purchaseEvents]
    );

    const defaultFilters = useMemo(() => {
        const eventIdFromUrl = tableFilterFormHelper.getInitialAutocompleteValues(
            'eventId',
            filterOptions,
            eventsDropdownOptions
        )?.[0];

        const defaultDropdownOption = { label: '', value: undefined };

        return { eventId: eventIdFromUrl || defaultDropdownOption };
    }, [eventsDropdownOptions, filterOptions]);

    return {
        defaultFilters,
        eventsDropdownOptions,
        hasFetchedEvents: !isLoading,
    };
};

const usePurchaseEventSeatingPlanCategoryFilters = (
    eventId: string | undefined,
    filterOptions: FilterOption[]
) => {
    const { data: seatingPlanCategories, isLoading } = useFetchSeatingPlanCategories(eventId || '');

    const hasFetchedSeatingPlanCategories = !eventId || !isLoading;

    const seatingPlanCategoriesDropdownOptions: FilterAutoCompleteOptions = useMemo(() => {
        const seatingCategories = seatingPlanCategories?.data?.data?.categories || [];

        return seatingCategories.map(({ id, name }) => ({
            value: id,
            label: name,
        }));
    }, [seatingPlanCategories]);

    const defaultFilters = useMemo(
        () => ({
            seatingPlanCategoryId: tableFilterFormHelper.getInitialAutocompleteValues(
                'seatingPlanCategoryId',
                filterOptions,
                seatingPlanCategoriesDropdownOptions
            ),
        }),
        [seatingPlanCategoriesDropdownOptions, filterOptions]
    );

    return {
        defaultFilters,
        hasFetchedSeatingPlanCategories,
        seatingPlanCategoriesDropdownOptions,
    };
};

export const useAdvancedEditPurchaseFilters = ({
    eventId,
    filterOptions,
    purchaseId,
}: {
    eventId: string | undefined;
    filterOptions: FilterOption[];
    purchaseId: string;
}) => {
    const {
        defaultFilters: eventDefaultValues,
        eventsDropdownOptions,
        hasFetchedEvents,
    } = usePurchaseEventsFilters({ filterOptions, purchaseId });
    const {
        defaultFilters: seatingPlanCategoryValues,
        hasFetchedSeatingPlanCategories,
        seatingPlanCategoriesDropdownOptions,
    } = usePurchaseEventSeatingPlanCategoryFilters(eventId, filterOptions);

    const hasFinishedFormingDefaultValues = hasFetchedEvents && hasFetchedSeatingPlanCategories;

    const defaultValues = useMemo(
        () => ({
            ...eventDefaultValues,
            ...seatingPlanCategoryValues,
            purchaseStatus: tableFilterFormHelper.getInitialAutocompleteValues(
                'purchaseStatus',
                filterOptions,
                purchaseStatusOptions
            ),
        }),
        [eventDefaultValues, seatingPlanCategoryValues, filterOptions]
    );

    return {
        defaultValues,
        /** Default values of Seating Plan Categories are dynamic and conditional.
         * Hence we must wait for the assigning operations to finish.
         * Example: Event ID in the url? -> fetch seating plan categories for that event -> select the default value */
        hasFinishedFormingDefaultValues,
        seatingPlanCategoriesDropdownOptions,
        eventsDropdownOptions,
    };
};
