import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import EventAutoCompleteFeature from 'src/app/components/features/event/EventAutoCompleteFeature';
import { SeatingPlanCategoriesAutocompleteFeature } from 'src/app/components/features/seating-plan-categories/SeatingPlanCategoriesAutocompleteFeature';
import { zodOptionalNumberInputSchema } from 'src/app/utilities/zod/zodOptionalNumberInputSchema';
import { zodRequiredDropdownSelectSchema } from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import { zodRequiredNumberInputSchema } from 'src/app/utilities/zod/zodRequiredNumberInputSchema';
import { dateOnlyFormat } from 'src/shared/date';
import { useGenericStyles } from 'src/shared/styles/genericStyles';
import { AutoCompleteValueOption } from 'src/view/components/auto-complete/interfaces';
import Button from 'src/view/components/button/Button';
import FormFieldError from 'src/view/components/form/FormFieldError';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import z from 'zod';

const useStyles = makeStyles((theme) => ({
    remove: {
        fontWeight: 'bold',
        marginTop: theme.spacing(0.5),
        color: theme.palette.error.main,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    link: {
        fontWeight: 'bold',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    addMore: {
        color: theme.colors.linkBlue,
    },
    delete: {
        marginLeft: theme.spacing(4),
        color: theme.palette.error.main,
    },
    categoryValue: {
        wordBreak: 'break-all',
    },
}));

type AutocompleteOption = {
    label: string;
    value: string;
};

export interface CategoryTicket {
    event: AutoCompleteValueOption<AutocompleteOption>;
    quantity: number;
    ticketsPerSplit: number | undefined;
    seatingplanCategory: AutoCompleteValueOption<AutocompleteOption>;
    pricePerTicket: number;
}

const defaultEvenTicketValues: CategoryTicket = {
    event: null,
    quantity: 0,
    ticketsPerSplit: undefined,
    seatingplanCategory: null,
    pricePerTicket: 0,
};

export interface AddPurchaseTicketsFormValues {
    events: CategoryTicket[];
}

export const categoryTicketsSchema = z.object({
    events: z.array(
        z
            .object({
                event: zodRequiredDropdownSelectSchema,
                seatingplanCategory: zodRequiredDropdownSelectSchema,
                quantity: zodRequiredNumberInputSchema,
                pricePerTicket: zodRequiredNumberInputSchema,
                ticketsPerSplit: zodOptionalNumberInputSchema.refine(
                    (val) => (val ? val !== 1 : true),
                    {
                        message: 'Split of 1 is not allowed',
                    }
                ),
            })
            .superRefine((scheme, ctx) => {
                if (scheme.ticketsPerSplit && scheme.quantity % scheme.ticketsPerSplit !== 0) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'Quantity should be dividable by tickets per split',
                        path: ['ticketsPerSplit'],
                    });
                }
            })
    ),
});

interface AdvancedEditAddPurchaseTicketsFormProps {
    onSubmitForm: (newCategoryTickets: AddPurchaseTicketsFormValues) => void;
}

export const AdvancedEditAddPurchaseTicketsForm = ({
    onSubmitForm,
}: AdvancedEditAddPurchaseTicketsFormProps) => {
    const classes = useStyles();
    const genericClasses = useGenericStyles();
    const [editCategoryMode, setEditCategoryMode] = useState(true);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const { control, setValue, watch, handleSubmit } = useForm<AddPurchaseTicketsFormValues>({
        mode: 'onChange',
        resolver: zodResolver(categoryTicketsSchema),
    });

    const events = watch('events');

    const alreadySelectedSeatingPlanCategories = events?.map((event) => {
        return {
            eventId: event.event?.value || '',
            seatingPlanCategoryId: event.seatingplanCategory?.value || '',
        };
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'events',
    });

    useEffect(() => {
        if (fields.length !== 0) return;

        append(defaultEvenTicketValues);
    }, []);

    const addEventField = () => {
        append(defaultEvenTicketValues);
    };

    function handleFormSubmit(values: AddPurchaseTicketsFormValues) {
        onSubmitForm(values);
        setHasSubmitted(true);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {hasSubmitted && (
                    <Alert severity="info">
                        Upon making new changes, please make sure to click the 'See affecting
                        changes' button to recalculate the fields.
                    </Alert>
                )}
            </Grid>
            <Grid item xs={2}>
                <FormLabel>Event*</FormLabel>
            </Grid>
            <Grid item xs={2}>
                <FormLabel>Quantity*</FormLabel>
            </Grid>
            <Grid item xs={2}>
                <FormLabel>Category*</FormLabel>
            </Grid>
            <Grid item xs={2}>
                <FormLabel>Price*</FormLabel>
            </Grid>
            <Grid item xs={2}>
                <FormLabel>Tickets per split</FormLabel>
            </Grid>
            <Grid item xs={2}></Grid>

            {fields.map((field, index) => {
                return (
                    <Grid container item spacing={1} alignItems="stretch" key={field.id}>
                        <Grid item xs={2}>
                            <Controller
                                name={`events.${index}.event`}
                                control={control}
                                render={({
                                    field: { name, value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <>
                                        <EventAutoCompleteFeature
                                            name={name}
                                            placeholder="Select an event"
                                            onChange={(values) => {
                                                setValue(
                                                    `events.${index}.seatingplanCategory`,
                                                    undefined
                                                );
                                                onChange(values);
                                            }}
                                            value={value}
                                            filter={[
                                                {
                                                    property: 'dateTimeStart',
                                                    operation: 'afterDate',
                                                    value: moment().format(dateOnlyFormat),
                                                },
                                            ]}
                                        />

                                        <FormFieldError message={error?.message} />
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controller
                                name={`events.${index}.quantity`}
                                control={control}
                                render={({
                                    field: { name, value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <>
                                        <Input
                                            name={name}
                                            type="number"
                                            onChange={onChange}
                                            value={value}
                                            placeholder="Quantity"
                                        />
                                        <FormFieldError message={error?.message} />
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controller
                                name={`events.${index}.seatingplanCategory`}
                                control={control}
                                render={({
                                    field: { value, onChange, name },
                                    fieldState: { error },
                                }) => (
                                    <>
                                        {!editCategoryMode && value ? (
                                            <div className={classes.categoryValue}>
                                                {value?.label} -{' '}
                                                <span
                                                    className={genericClasses.link}
                                                    onClick={() => setEditCategoryMode(true)}
                                                >
                                                    Change
                                                </span>
                                            </div>
                                        ) : (
                                            <>
                                                <SeatingPlanCategoriesAutocompleteFeature
                                                    name={name}
                                                    eventId={events[index].event?.value ?? ''}
                                                    onChange={(value) => {
                                                        if (value) setEditCategoryMode(false);

                                                        onChange(value);
                                                    }}
                                                    value={value ?? undefined}
                                                    disabled={!events[index].event?.value}
                                                    enableLabelTooltip
                                                    hideArchivedSpcs
                                                    excludedSeatingPlanCategories={
                                                        alreadySelectedSeatingPlanCategories
                                                    }
                                                    placeholder="Select a seating plan category"
                                                />
                                                <FormFieldError message={error?.message} />
                                            </>
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controller
                                name={`events.${index}.pricePerTicket`}
                                control={control}
                                render={({
                                    field: { name, value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <>
                                        <Input
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                            placeholder="Price"
                                            type="number"
                                        />
                                        <FormFieldError message={error?.message} />
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controller
                                name={`events.${index}.ticketsPerSplit`}
                                control={control}
                                render={({
                                    field: { name, value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <>
                                        <Input
                                            name={name}
                                            type="number"
                                            onChange={onChange}
                                            value={value}
                                            placeholder="Tickets per split"
                                        />
                                        <FormFieldError message={error?.message} />
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} alignSelf="center">
                            {fields.length !== 1 && (
                                <span
                                    className={classnames(classes.link, classes.delete)}
                                    onClick={() => remove(index)}
                                >
                                    Remove
                                </span>
                            )}
                        </Grid>
                    </Grid>
                );
            })}

            <Grid item xs={12} display="flex">
                <span
                    className={classnames(classes.link, classes.addMore)}
                    onClick={() => addEventField()}
                >
                    Add more tickets
                </span>
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={handleSubmit((values) => {
                        handleFormSubmit(values);
                    })}
                    color="primary"
                >
                    See affecting changes
                </Button>
            </Grid>
        </Grid>
    );
};
