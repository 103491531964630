import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { CSSProperties } from '@mui/styles';
import { SIZES } from 'src/shared/theme/enums';
import { ChildlessBaseComponent } from 'src/view/interfaces/BaseComponent';

export interface IconComponentProps extends ChildlessBaseComponent {
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    Icon:
        | OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>
        | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    size?: SIZES;
    grey?: boolean;
    contrast?: boolean;
    success?: boolean;
    error?: boolean;
    warning?: boolean;
    color?: CSSProperties['color'];
}

const useStyles = makeStyles((theme: Theme) => ({
    icon: (props: IconComponentProps) => {
        let height = theme.layout.icon[SIZES.MEDIUM].height;
        let fill = theme.colors.grey;
        let stroke = theme.colors.grey;

        switch (props.size) {
            case SIZES.TINY:
                height = theme.layout.icon[SIZES.TINY].height;
                break;
            case SIZES.SMALL:
                height = theme.layout.icon[SIZES.SMALL].height;
                break;
            case SIZES.LARGE:
                height = theme.layout.icon[SIZES.LARGE].height;
                break;
        }

        if (props.color) {
            fill = props.color;
            stroke = props.color;
        }

        if (props.success) {
            fill = theme.palette.success.main;
            stroke = theme.palette.success.main;
        }

        if (props.warning) {
            fill = theme.palette.warning.main;
            stroke = theme.palette.warning.main;
        }

        if (props.error) {
            fill = theme.palette.error.main;
            stroke = theme.palette.error.main;
        }

        if (props.contrast) {
            fill = theme.palette.primary.contrastText;
            stroke = theme.palette.primary.contrastText;
        }

        return {
            height,
            '& path, svg circle': {
                stroke,
                fill,
            },
        };
    },
}));

const IconComponent = ({
    className,
    size,
    Icon,
    onClick,
    contrast,
    success,
    warning,
    error,
    color,
}: IconComponentProps): JSX.Element => {
    const classes = useStyles({ size, Icon, success, error, contrast, color, warning });

    return <Icon onClick={onClick} className={`${classes.icon} ${className || ''}`} />;
};

export default IconComponent;
