import { AdvancedEditPurchaseFormValues } from 'src/app/components/features/purchase/AdvancedEditPurchaseDetailsForm';
import { EditPurchasePricesFormSubmitValues } from 'src/app/components/forms/purchase/EditPurchasePricesForm';
import { PurchasePrice, UpdatePurchaseDto } from 'src/data/services/purchaseService';

export function mapEditPurchaseFormValuesToDto({
    purchaseOriginalPrice,
    seatingPlanCategoryPrices,
}: EditPurchasePricesFormSubmitValues) {
    return {
        purchaseOriginalPrice,
        seatingPlanCategoryPrices: seatingPlanCategoryPrices.map(
            ({ eventId, seatingPlanCategoryId, pricePerTicket }) => {
                return {
                    eventId,
                    seatingPlanCategoryId,
                    pricePerTicket,
                };
            }
        ),
    };
}

export function mapAdvancedEditPurchaseDetailsFormToDto(
    values: AdvancedEditPurchaseFormValues
): UpdatePurchaseDto {
    const paymentMethodDto = values.paymentMethod?.value
        ? {
              type: values.paymentMethod?.value,
              creditCardInfo: values.creditcardNumber,
          }
        : undefined;

    return {
        paymentMethod: paymentMethodDto,
        notes: values.internalNotes,
        externalReferenceId: values.externalReference,
        finalized: !!values.finalized,
        purchaseType: values.purchaseType?.value,
    };
}

export function mapPurchasePricesDataToEditPurchasePricesForm(purchasePrices: PurchasePrice[]) {
    return purchasePrices.map(
        ({
            eventId,
            eventName,
            originalPrice,
            quantity,
            seatingPlanCategoryId,
            seatingPlanCategoryName,
        }) => {
            return {
                eventId,
                eventName,
                originalPrice,
                quantity,
                seatingPlanCategoryId,
                seatingPlanCategoryName,
            };
        }
    );
}
